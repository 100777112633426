<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{ t("headings.key") }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="grid gap-4">
      <div class="card p-4">
        <div
          class="grid grid-cols-1 gap-x-12 gap-y-4 p-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3"
        >
          <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
            <div>
              <label class="block">{{ t("labels.stamp") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ key.stamp }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
            <div>
              <label class="block">{{ t("labels.estate_name") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ estate.name }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
            <div>
              <label class="block">{{ t("labels.address") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{
                getAddress(estate.buildings, key.buildingId)
              }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
            <div>
              <label class="block">{{ t("labels.borrower_name") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ loan.borrower }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
            <div>
              <label class="block">{{ t("labels.date_borrowed") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ getLocaleDate(locale, loan.dateBorrowed) }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
            <div>
              <label class="block">{{ t("labels.date_returned") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ getLocaleDate(locale, loan.dateReturned) }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
            <div>
              <label class="block">{{ t("labels.lender_name") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ loan.lender }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
            <div>
              <label class="block">{{ t("labels.receiver_name") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ loan.receiver }}</span>
            </div>
          </div>
          <div
            class="flex flex-col gap-y-4 sm:col-span-3 sm:py-4 xl:justify-center"
          >
            <div>
              <label class="block">{{ t("labels.signature") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <img v-else :src="file" />
            </div>
          </div>
        </div>
        <hr class="my-4 border-slate-300" />
        <div class="flex justify-end">
          <form-button
            :command="() => router.back()"
            :disabled="waiting"
            id="profile-edit-button"
            label="back"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import { getLocaleDate } from "../utils/dateUtils";

export default {
  components: { Breadcrumb, FormButton, Skeleton },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const company = computed(() => store.state.company.company);
    const estate = computed(() => store.state.estate.estate);
    const file = computed(() => store.state.file.file);
    const key = computed(() => store.state.key.key);
    const loan = computed(() => store.state.key.loan);

    const waiting = computed(() => {
      if (store.state.estate.waiting) return true;
      if (store.state.key.waiting) return true;
      return false;
    });

    const getAddress = (buildings, buildingId) => {
      if (!buildings?.length || !buildingId) return;
      const building = buildings.find((building) => building.id === buildingId);
      return `${building.address}, ${building.postalCode} ${building.city}`;
    };

    onMounted(() => {
      store.dispatch("key/getKey", route.params.keyId);
      store.dispatch("key/getKeyLoan", route.params.loanId);
    });

    watch(
      () => key.value,
      (val) => {
        store.dispatch("estate/getEstate", val.estateId);
      }
    );

    watch(
      () => loan.value,
      (val) => {
        store.dispatch("file/getFile", val.file.path);
      }
    );

    return {
      company,
      estate,
      file,
      getAddress,
      getLocaleDate,
      key,
      loan,
      locale,
      router,
      t,
      waiting,
    };
  },
};
</script>
